* {
  box-sizing: border-box;
  font-family: "Mali";
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
}

body {
  background-color: #f2f2f2;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
